import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: {
      requireAuth: false,
    },
    component: () => import('../views/login/LoginView.vue')
  },
  {
    path: '/',
    name: 'index',
    meta: {
      requireAuth: true,
    },
    redirect: '/dashboard',
    component: () => import('../views/index/MainView.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/dashboard/DashboardView.vue')
      },
      {
        path: '/wcal/notice',
        name: 'wcal-notice',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/wcal/notice/NoticeListView.vue')
      },
      {
        path: '/wcal/cdk',
        name: 'wcal-cdk',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/wcal/cdk/CdkListView.vue')
      },
      {
        path: '/scal/notification',
        name: 'scal-notification',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/scal/notification/NotificationListView.vue')
      },
      {
        path: '/scal/user',
        name: 'scal-user',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/scal/user/UserListView.vue')
      },
      {
        path: '/scal/payment',
        name: 'scal-payment',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/scal/payment/PaymentListView.vue')
      },
      {
        path: '/scal/vipcode',
        name: 'scal-vipcode',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/scal/vipcode/VipcodeListView.vue')
      },
      {
        path: '/scal/config',
        name: 'scal-config',
        meta: {
          requireAuth: false,
        },
        component: () => import('../views/scal/config/GlobalConfigView.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
