export class LocalStorageService {
    _isLogin?: boolean;
    _username?: string | null;

    isLogin(): boolean {
        if (this._isLogin == undefined) {
            this._isLogin = "true" === localStorage.getItem("isLogin");
        }
        return this._isLogin;
    }

    setLogin(value: boolean): void {
        if (value) {
            localStorage.setItem("isLogin", "true");
            this._isLogin = true;
        } else {
            localStorage.removeItem("isLogin");
            this._isLogin = false;
        }
    }

    getUsername(): string {
        if (this._username == undefined) {
            this._username = localStorage.getItem("username");
        }
        return this._username == null ? "" : this._username;
    }

    setUsername(value: string | undefined) {
        if (value == undefined) {
            localStorage.removeItem("username");
            this._username = value;
        } else {
            localStorage.setItem("username", value);
            this._username = value;
        }
    }
}